import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Union } from "@/__main__/data-model.mjs";

const TierlistDef = Mapped({
  key: String, // Platform (PC, Console),
  value: Mapped({
    key: Union(["Defender", "Duelist", "Strategist"]),
    value: Mapped({
      key: String, // Hero name
      value: {
        id: String,
        hero: String,
        tier: Number,
        pickRate: String, // "19.70%"
        winRate: String, // "55.28%"
      },
    }),
  }),
}) satisfies ModelDefinition;

export const TierlistModel = createModel(TierlistDef);

export type Tierlist = DeepReadonly<FromModel<typeof TierlistModel>>;
